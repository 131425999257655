import React from 'react';
import ReactDOM from 'react-dom';
// import thunk from 'redux-thunk';
// import { browserHistory } from 'react-router';

import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

// import reducers from './reducers';
import App from './components/app';

import { persistor, store } from './store';
import './style.scss';

// this creates the store with the reducers, and does some other stuff to initialize devtools
// boilerplate to copy, don't have to know
// const store = createStore(reducers, {}, compose(
//   applyMiddleware(thunk),
//   window.devToolsExtension ? window.devToolsExtension() : f => f,
// ));

// we now wrap App in a Provider
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  , document.getElementById('main'),
);
