import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../actions';

class SignIn extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
      username: '',
      password: '',
      // userId: '',
      // token: "",
      // dataSource: null,
      signInAttempted: false,
      // badSignIn: false,
      // modalVisible: false,
      // isPressed: false,
    };
    this.onSignIn = this.onSignIn.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user && this.props.user.current != null) {
      if (this.props.user.current.profile !== nextProps.user.current.profile) {
        this.props.signIn();
      }
    }
  }
  onSignIn() {
    if (this.state.username !== '' && this.state.password !== '') {
      this.verify();
    }
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { // 13 is the enter keycode
      this.onSignIn();
    }
  }

  verify() {
    const user = { username: this.state.username, password: this.state.password };
    this.props.verifyUser(user);
  }

  render() {
    return (
      <div className="mainComponent">
        <div className="signInComponent">
          <TextField
            id="username"
            label="Username"
            onChange={event => this.setState({ username: event.target.value })}
            margin="normal"
          />
          <TextField
            id="password"
            label="Password"
            placeholder="Password"
            onChange={event => this.setState({ password: event.target.value })}
            type="password"
            margin="normal"
            onKeyPress={this.enterPressed}
          />
          <div className=".centered">
            <Button onClick={this.onSignIn} id="bluebutton">Sign In</Button>
          </div>
          <div>
            {this.state.signInAttempted && (
            <p> Sorry this username password combo is not recognized </p>
              )}
            <p> Dont have an account? Download our app and sign up! </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(SignIn);
