import { ActionTypes } from '../actions';

const activity = {
  onView: {},
};

const ActivityReducer = (state = activity, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ACTIVITY:
      return {
        onView: action.payload,
      };
    default:
      return state;
  }
};

export default ActivityReducer;
