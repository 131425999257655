import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import Avatar from 'material-ui/Avatar';

import SearchBar from './searchBar';

import LogoImage from '../img/Harbor-Logo-White.png';

function LoggedInAppBar(props) {
  return (
    <div className="root">
      <AppBar position="static" className="appbar">
        <Toolbar className="toolbar">
          <div className="leftNav">
            <Button className="button" component={Link} to="/"><img src={LogoImage} alt="Home Icon" height="39" width="39" /></Button>
            <SearchBar className="searchBar" />
          </div>
          <div className="rightNav">
            <Button className="button" component={Link} to="/messages"><Icon className="far fa-envelope" /></Button>
            <Button className="button" component={Link} to="/profile"><Avatar className="navatar" alt={props.profile.fullname} src={props.profile.picture} /></Button>
            <Button className="button" onClick={props.signOut}>Sign Out</Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default LoggedInAppBar;
