import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

import Button from 'material-ui/Button';

import * as actions from '../actions';

function RegistrationButton(props) {
  if (props.registered) {
    return (
      <Button className="bluebutton" onClick={props.unregister}> Unregister </Button>
    );
  } else {
    return (
      <Button className="bluebutton" onClick={props.register}> Register Now </Button>
    );
  }
}

class ActivityPage extends Component {
  constructor(props) {
    super(props);
    // init component state here
    let registered = false;
    for (let i = 0; i < this.props.user.current.current.classes.length; i += 1) {
      if (this.props.user.current.current.classes[i]._id === this.props.match.params.id) { registered = true; }
    }
    this.state = {
      registered,
    };

    console.log(registered);
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);
  }

  componentWillMount() {
    this.props.fetchActivity(this.props.match.params.id);
  }

  register() {
    this.props.addActivity(this.props.user.current._id, this.props.activity.onView._id);
    this.props.fetchUser(this.props.user.current._id);
    this.setState({ registered: true });
  }

  unregister() {
    console.log('BLAH');
    this.props.removeActivity(this.props.user.current._id, this.props.activity.onView._id);
    this.props.fetchUser(this.props.user.current._id);
    this.setState({ registered: false });
  }

  render() {
    if (this.props.activity.onView.information) {
      return (
        <div className="mainComponents">
          <div className="row">
            <div>
              <h1> {this.props.activity.onView.information.businessname} </h1>
              <h1> {this.props.activity.onView.information.name} </h1>
            </div>
          </div>
          <div className="bio">
            <p> <span className="descriptor">Address: </span> {this.props.activity.onView.information.address}  </p>
            <p> <span className="descriptor">Time: </span> {this.props.activity.onView.information.date} </p>
            <p> {this.props.activity.onView.people.length} People Are Planning on Attending </p>
            <p> <span className="descriptor">Price: </span>${this.props.activity.onView.information.price} </p>
            <p> <span className="descriptor">Description: </span>{this.props.activity.onView.information.description} </p>
            <div className="row">
              <img className="activityImg" alt={this.props.activity.onView.information.name} src={this.props.activity.onView.information.picture1} />
              <img className="activityImg" alt={this.props.activity.onView.information.name} src={this.props.activity.onView.information.picture2} />
            </div>
            <div className="centered">
              <RegistrationButton
                registered={this.state.registered}
                register={this.register}
                unregister={this.unregister}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p> Loading.... </p>
        </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    activity: state.activity,
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(ActivityPage);
