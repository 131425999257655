import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';
import { Link } from 'react-router-dom';

import * as actions from '../actions';

class Profile extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
    };
  }

  // Ask Tim About this (the loading and if the unordered list/mapping is correct)
  render() {
    if (this.props.user.current.profile) {
      return (
        <div className="mainComponents">
          <div className="row">
            <div>
              <h1> {this.props.user.current.profile.fullname} </h1>
              <h1> @{this.props.user.current.profile.username} </h1>
            </div>
            <Avatar id="bigavatar" alt={this.props.user.current.profile.fullname} src={this.props.user.current.profile.picture} />
          </div>
          <div className="bio">
            <p> Signed up for {this.props.user.current.current.classes.length} classes </p>
            <p> {this.props.user.current.status} </p>
            <p> <span className="descriptor"> Bio: </span> {this.props.user.current.profile.bio} </p>
            <p> <span className="descriptor"> Interests: </span>
              {this.props.user.current.profile.interests.map((interest) => {
                return (<span className="interests" key={interest}>{interest} </span>);
              })}
            </p>
            <p> <span className="descriptor"> Upcoming Classes: </span> </p>
            <ul>
              {this.props.user.current.current.classes.map((activity) => {
                return (
                  <li key={activity._id}>
                    <Link to={`/activity/${activity._id}`}> {activity.information.name} </Link>
                  </li>
                );
            })}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p> Loading.... </p>
        </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    user: state.user,
  }
);

export default connect(mapStateToProps, actions)(Profile);
