import React, { Component } from 'react';
import { MuiThemeProvider } from 'material-ui/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';


import 'axios';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { persistor } from '../store';


import Theme from './theme';
import LoggedOutAppBar from './loggedOutAppBar';
import LoggedInAppBar from './LoggedInAppBar';

// Logged Out Pages
import LandingPage from './landingPage';
// import Philosophy from './philosophy';
// import Explore from './explore';
import Fallback from './fallback';
// import SignUp from './signup';
import SignIn from './signin';

// Logged in Pages
import Feed from './feed';
import Search from './search';
import Profile from './profile';
import UserProfile from './userProfile';
import ActivityPage from './activityPage';
import Messages from './messages';


class App extends Component {
  constructor(props) {
    let loggedIn = false;
    super(props);
    if (this.props.user && this.props.user.current._id != null) {
      loggedIn = true;
    }
    // init component state here
    this.state = {
      loggedIn,
    };
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signIn() {
    this.setState({ loggedIn: true });
    this.props.fetchUser(this.props.user.current._id);
  }

  signOut() {
    this.props.logout();
    persistor.purge();
    this.setState({ loggedIn: false });
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <div>
          <MuiThemeProvider theme={Theme}>
            <div>
              <Router>
                <div className="bodyBackground">
                  <LoggedInAppBar signOut={this.signOut} profile={this.props.user.current.profile} />
                  <Switch>
                    <Route exact path="/" component={Feed} />
                    <Route path="/search" component={Search} />
                    <Route path="/profile" component={Profile} />
                    <Route exact path="/user/:id" component={UserProfile} />
                    <Route exact path="/activity/:id" component={ActivityPage} />
                    <Route path="/messages" component={Messages} />
                    <Route
                      path="/signin"
                      render={() =>
                        (<Redirect
                          to="/"
                        />)
                      }
                    />
                    <Route component={Fallback} />
                  </Switch>
                </div>
              </Router>
            </div>
          </MuiThemeProvider>
        </div>
      );
    } else {
      return (
        <div>
          <MuiThemeProvider theme={Theme}>
            <div>
              <Router>
                <div className="bodyBackground">
                  <LoggedOutAppBar />

                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() =>
                        (<LandingPage
                          loggedIn={this.state.loggedIn}
                        />)
                      }
                    />
                    <Route
                      path="/signin"
                      render={() =>
                        (<SignIn
                          signIn={this.signIn}
                          loggedIn={this.state.loggedIn}
                        />)
                      }
                    />
                    <Route
                      path="/search"
                      render={() =>
                        (<Redirect
                          to="/"
                        />)
                      }
                    />
                    <Route
                      path="/profile"
                      render={() =>
                        (<Redirect
                          to="/"
                        />)
                      }
                    />
                    <Route
                      path="/user"
                      render={() =>
                        (<Redirect
                          to="/"
                        />)
                      }
                    />
                    <Route
                      path="/messages"
                      render={() =>
                        (<Redirect
                          to="/"
                        />)
                      }
                    />
                    <Route component={Fallback} />
                  </Switch>
                </div>
              </Router>
            </div>
          </MuiThemeProvider>
        </div>
      );
    }
  }
}
const mapStateToProps = state => (
  {
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(App);
