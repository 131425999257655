import React, { Component } from 'react';
import Tabs, { Tab } from 'material-ui/Tabs';
import AppBar from 'material-ui/AppBar';
import { connect } from 'react-redux';


import Results from './searchResults';
import * as actions from '../actions';

class Search extends Component {
  constructor(props) {
    super(props);
    let searchtext = '';
    if (this.props.searchResults && this.props.searchResults.searchterm) {
      searchtext = this.props.searchResults.searchterm;
    }
    this.state = {
      searchterm: searchtext,
      selected: 0,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.searchResults && this.props.searchResults.searchterm && this.state.searchterm !== this.props.searchResults.searchterm) {
      this.setState({ searchterm: this.props.searchResults.searchterm });
    }
  }

  search(filter, text) {
    if (filter === 0 || filter === 1) { this.props.userSearch(this.state.searchterm); }
    if (filter === 0 || filter === 2) { this.props.activitySearch(this.state.searchterm); }
  }

  handleTabChange(event, value) {
    this.setState({ selected: value });
    if (this.state.searchterm !== '') {
      this.search(value, this.state.searchterm);
    }
  // eventually, it should automatically research when you change tabs
  }

  render() {
    return (
      <div className="searchGrow">
        <AppBar position="static">
          <Tabs value={this.state.selected} onChange={this.handleTabChange}>
            <Tab label="All" />
            <Tab label="People" />
            <Tab label="Activities" />
          </Tabs>
        </AppBar>
        <Results selected={this.state.selected} searchterm={this.state.searchterm} />
      </div>

    );
  }
}

const mapStateToProps = state => (
  {
    searchResults: state.searchResults,
  }
);
export default connect(mapStateToProps, actions)(Search);
