import axios from 'axios';
// import { browserHistory } from 'react-router';

// const ROOT_URL = 'http://localhost:9090/api';
const ROOT_URL = 'https://harbor-backend.herokuapp.com/api';

// keys for actiontypes
export const ActionTypes = {
  FETCH_USER: 'FETCH_USER',
  FETCH_ON_VIEW_USER: 'FETCH_ON_VIEW_USER',
  VERIFY_USER: 'VERIFY_USER',
  ADD_ACTIVITY: 'ADD_ACTIVITY',
  REMOVE_ACTIVITY: 'REMOVE_ACTIVITY',
  LOG_OUT: 'LOG_OUT',

  FETCH_ACTIVITY: 'FETCH_ACTIVITY',

  SET_SEARCHTERM: 'SET_SEARCHTERM',
  SEARCH_USER: 'SEARCH_USER',
  SEARCH_ALL_USERS: 'SEARCH_ALL_USERS',
  SEARCH_ACTIVITY: 'SEARCH_ACTIVITY',
  SEARCH_ALL_ACTIVITIES: 'SEARCH_ALL_ACTIVITIES',

  FETCH_FEED: 'FETCH_FEED',

  FETCH_CONVERSATION: 'FETCH_CONVERSATION',
  FETCH_CONVERSATIONS: 'FETCH_CONVERSATIONS',

};

/* USER based actions below
------------------------------------------------------------- */

export function fetchUser(id) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/${id}`).then((response) => {
      dispatch({ type: 'FETCH_USER', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function fetchOnViewUser(id) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/${id}`).then((response) => {
      dispatch({ type: 'FETCH_ON_VIEW_USER', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function verifyUser(user) {
  return (dispatch) => {
    const fields = { username: user.username, password: user.password };
    axios.post(`${ROOT_URL}/verify`, fields).then((response) => {
      dispatch({ type: 'VERIFY_USER', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function addActivity(user, activity) {
  return (dispatch) => {
    const fields = { userId: user, activityId: activity };
    axios.put(`${ROOT_URL}/users/activities/${user}/${activity}`, fields).then((response) => {
      dispatch({ type: 'ADD_ACTIVITY', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function removeActivity(user, activity) {
  return (dispatch) => {
    const fields = { userId: user, activityId: activity };
    axios.put(`${ROOT_URL}/users/activities/remove/${user}/${activity}`, fields).then((response) => {
      dispatch({ type: 'REMOVE_ACTIVITY', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: 'LOG_OUT' });
  };
}

/* ACTIVITY based actions below
------------------------------------------------------------- */

export function fetchActivity(id) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/activities/${id}`).then((response) => {
      dispatch({ type: 'FETCH_ACTIVITY', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

/* FEED based actions below
------------------------------------------------------------- */

export function fetchFeed() {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/feed`).then((response) => {
      dispatch({ type: 'FETCH_FEED', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

/* SEARCH based actions below
------------------------------------------------------------- */

/* Depricated
export function search(searchType, searchTerm) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/${searchType}/${searchTerm}`).then((response) => {
      dispatch({ type: 'SEARCH', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
} */

export function setSearchTerm(searchTerm) {
  return (dispatch) => {
    dispatch({ type: 'SET_SEARCHTERM', payload: searchTerm });
  };
}

export function userSearch(searchTerm) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/searchusername/${searchTerm}`).then((response) => {
      dispatch({ type: 'SEARCH_USER', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function searchAllUsers() {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/`).then((response) => {
      console.log(response.data);
      dispatch({ type: 'SEARCH_ALL_USERS', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function searchAllActivities() {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/activities/`).then((response) => {
      dispatch({ type: 'SEARCH_ALL_ACTIVITIES', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function searchAll() {
  searchAllUsers();
  searchAllActivities();
}

export function activitySearch(searchTerm) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/searchactivityname/${searchTerm}`).then((response) => {
      dispatch({ type: 'SEARCH_ACTIVITY', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

/* CONVERSATION based actions below
------------------------------------------------------------- */

export function fetchConversations(userId) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/conversations/participant/${userId}`).then((response) => {
      dispatch({ type: 'FETCH_CONVERSATIONS', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}

export function fetchConversation(conversationId) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/conversations/${conversationId}`).then((response) => {
      dispatch({ type: 'FETCH_CONVERSATION', payload: response.data });
    }).catch((error) => {
      console.log(error);
    });
  };
}
