import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as actions from '../actions';

function People(props) {
  if (props.searchResults.user && props.searchResults.user[0]) {
    if (props.searchResults.user[0].profile) {
      return (
        <div>
          <ul className="search">
            {props.searchResults.user.map((result) => {
            return (
              <li key={result._id} className="searchItem">
                <Link to={`/user/${result._id}`}> {result.profile.username} </Link>
              </li>
            );
          })}
          </ul>
        </div>
      );
    } else {
      return (
        <p> Loading... </p>
      );
    }
  } else {
    return (
      <p> No Results Found. </p>
    );
  }
}

function Activities(props) {
  if (props.searchResults.activity[0]) {
    if (props.searchResults.activity[0].information) {
      return (
        <div>
          <ul className="search">
            {props.searchResults.activity.map((result) => {
            return (
              <li key={result._id} className="searchItem">
                <Link to={`/activity/${result._id}`}> {result.information.name} </Link>
              </li>
            );
          })}
          </ul>
        </div>
      );
    } else {
      return (
        <p> Loading... </p>
      );
    }
  } else {
    return (
      <p> No Results Found. </p>
    );
  }
}

class SearchResults extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
      selected: this.props.selected,
      searchterm: this.props.searchterm,
    };
    if (this.props.searchterm === '' || undefined) {
      this.props.searchAllUsers();
      this.props.searchAllActivities();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selected !== nextProps.selected) {
      this.setState({ selected: nextProps.selected });
    }
    if (this.state.searchterm !== nextProps.searchterm) {
      this.setState({ searchterm: nextProps.searchterm });
    }
  }

  render() {
    if (this.state.selected === 0) {
      return (
        <div className="searchComponent">
          <h1> People </h1>
          <People searchterm={this.state.searchterm} searchResults={this.props.searchResults} />
          <h1> Activities </h1>
          <Activities searchterm={this.state.searchterm} searchResults={this.props.searchResults} />
        </div>
      );
    } else if (this.state.selected === 1) {
      return (
        <div className="searchComponent">
          <h1> People </h1>
          <People searchterm={this.state.searchterm} searchResults={this.props.searchResults} />
        </div>
      );
    } else if (this.state.selected === 2) {
      return (
        <div className="searchComponent">
          <h1> Activities </h1>
          <Activities searchterm={this.state.searchterm} searchResults={this.props.searchResults} />
        </div>
      );
    } else {
      return (
        <div />
      );
    }
  }
}

const mapStateToProps = state => (
  {
    searchResults: state.searchResults,
  }
);
export default connect(mapStateToProps, actions)(SearchResults);
