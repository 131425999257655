import React, { Component } from 'react';

class Fallback extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <h1> URL not found </h1>
      </div>
    );
  }
}

export default Fallback;
