import { ActionTypes } from '../actions';

const conversations = {
  current: {},
  all: [],
};

const ConversationReducer = (state = conversations, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONVERSATIONS:
      return {
        current: state.current,
        all: action.payload,
      };
    case ActionTypes.FETCH_CONVERSATION:
      return {
        current: action.payload,
        all: state.all,
      };
    default:
      return state;
  }
};

export default ConversationReducer;
