import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';

class Message extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
      selectedMessage: this.props.selectedMessage,
      selectedUserName: this.props.selectedUserName,
    };
  }

  componentWillMount() {
    if (this.state.selectedMessage) {
      this.props.fetchConversation(this.state.selectedMessage);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedMessage !== nextProps.selectedMessage) {
      this.setState({ selectedMessage: nextProps.selectedMessage });
      this.props.fetchConversation(nextProps.selectedMessage);
    }
    if (this.state.selectedUserName !== nextProps.selectedUserName) {
      this.setState({ selectedUserName: nextProps.selectedUserName });
    }
  }

  render() {
    if (this.props.conversations && this.props.conversations.current && this.props.conversations.current.messages) {
      return (
        <div>
          <h1> {this.state.selectedUserName} </h1>
          <ul className="messages">
            {this.props.conversations.current.messages.map((message) => {
            if (message.sender === this.props.user.current._id) {
              return (<li className="myMessage" key={message._id}>{message.text}</li>);
            } else {
              return (<li className="theirMessage" key={message._id}>{message.text} </li>);
            }
          })}
          </ul>
        </div>
      );
    } else {
      return (<div />);
    }
  }
}
const mapStateToProps = state => (
  {
    conversations: state.conversations,
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(Message);
