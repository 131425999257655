import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import Message from './message';

class Messages extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
      selectedMessage: null,
    };
    this.handleMessageSelection = this.handleMessageSelection.bind(this);
  }

  componentWillMount() {
    this.props.fetchConversations(this.props.user.current._id);
  }

  handleMessageSelection(conversation) {
    this.setState({
      selectedMessage: conversation._id,
      selectedUserName: (conversation.participants[0]._id === this.props.user.current._id) ? conversation.participants[1].profile.fullname : conversation.participants[0].profile.fullname,
    });
  }

  render() {
    if (this.props.conversations && this.props.conversations.all) {
      return (
        <div className="mainComponents">
          <h1> Messages </h1>
          <div className="messageView">
            <ul className="names">
              {this.props.conversations.all.map((conversation) => {
                return (
                  <li key={conversation._id} onClick={() => this.handleMessageSelection(conversation)}>
                    {(conversation.participants[0]._id === this.props.user.current._id) ? conversation.participants[1].profile.fullname : conversation.participants[0].profile.fullname}
                  </li>);
                })}
            </ul>
            <Message selectedMessage={this.state.selectedMessage} selectedUserName={this.state.selectedUserName} />
          </div>
        </div>
      );
    } else {
      return (
        <div> Loading.... </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    conversations: state.conversations,
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(Messages);
