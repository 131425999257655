import React, { Component } from 'react';
import Grid from 'material-ui/Grid';
// import Input from 'material-ui/Input';


class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Grid container className="root">
          <Grid item xs={12}>
            <Grid
              container
              className="header"
              alignItems="center"
              direction="column"
              justify="center"
            >
              <div className="overlay">
                <h2> Welcome to Harbor </h2>
                <h2> New friends, skills, and adventures await. </h2>
                <h2> Join us now! </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/*
  <Grid item xs={12}>
    <Grid
      container
      className="plain"
      alignItems="flex-start"
      direction="column"
      justify="flex-start"
    >
      <h1> Want more? </h1>
      <h2> Join our list so we can send you additional info </h2>
      <h2> Join us now! </h2>
      <Input
        placeholder="Email"
        className="input"
        inputProps={{
        'aria-label': 'Description',
        }}
      />
    </Grid>
  </Grid>
  */

// export default LandingPage;

export default LandingPage;
