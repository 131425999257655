import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';

import LogoImage from '../img/Harbor-Logo-White.png';

function LoggedOutAppBar(props) {
  // return (
  //   <div className="root">
  //     <AppBar position="static" className="appbar">
  //       <Toolbar className="toolbar">
  //         <div className="leftNav">
  //           <Button className="button" component={Link} to="/">HOME LOGO</Button>
  //           <Button component={Link} to="/philosophy">Our Philosophy</Button>
  //           <Button component={Link} to="/explore">Explore Classes</Button>
  //         </div>
  //         <div className="rightNav">
  //           <Button component={Link} to="/signup">Sign Up</Button>
  //           <Button component={Link} to="/signin">Sign In</Button>
  //         </div>
  //       </Toolbar>
  //     </AppBar>
  //   </div>
  // );
  return (
    <div className="root">
      <AppBar position="static" className="appbar">
        <Toolbar className="toolbar">
          <div className="leftNav">
            <Button className="button" component={Link} to="/"><img src={LogoImage} alt="Home Icon" height="39" width="39" /></Button>
          </div>
          <div className="rightNav">
            <Button className="button" component={Link} to="/signin">Sign In</Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default LoggedOutAppBar;
