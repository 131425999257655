import { createMuiTheme } from 'material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#03BFE9',
    },
  },
  shadows: ['none'],
  background: {
    paper: '#fff',
    default: 'transparent',
  },
});

export default theme;
