import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';

import * as actions from '../actions';

class Feed extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
    };
  }

  componentWillMount() {
    this.props.fetchFeed();
  }

  render() {
    if (this.props.feed && this.props.feed.current) {
      if (this.props.feed.current[0]) {
        return (
          <div className="mainComponents">
            <h1> Feed </h1>
            <ul className="feed">
              {this.props.feed.current.map((feedItem) => {
                if (feedItem.actionText === 'signed up for') {
                  return (
                    <li className="feedItem" key={feedItem._id}>
                      <Avatar className="avatar" alt={feedItem.subject.profile.fullname} src={feedItem.subject.profile.picture} />
                      <p> {`${feedItem.subject.profile.fullname} ${feedItem.actionText} ${feedItem.object.item.information.name}`} </p>
                    </li>);
                } else {
                  return (
                    <li className="feedItem" key={feedItem._id}>
                      <Avatar className="avatar" alt={feedItem.subject.profile.fullname} src={feedItem.subject.profile.picture} />
                      <p> {`${feedItem.subject.profile.fullname} ${feedItem.actionText} ${feedItem.object.item.profile.fullname}`} </p>
                    </li>);
                }
              })}
            </ul>
          </div>
        );
      } else {
        return (
          <div>
            <h1> Feed </h1>
            <p> No feed items found at the moment. Check back later! </p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <h1> Loading... </h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    feed: state.feed,
    user: state.user,
  }
);
export default connect(mapStateToProps, actions)(Feed);
