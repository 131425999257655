import { ActionTypes } from '../actions';

const user = {
  current: {},
  onView: {},
};

const UserReducer = (state = user, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_USER:
      return {
        current: action.payload,
        onView: state.onView,
      };
    case ActionTypes.VERIFY_USER:
      if (action.payload != null) {
        return {
          current: action.payload,
          onView: state.onView,
        };
      } else {
        return state;
      }
    case ActionTypes.FETCH_ON_VIEW_USER:
      return {
        current: state.current,
        onView: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
