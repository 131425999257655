import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import { connect } from 'react-redux';

import * as actions from '../actions';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    // init component state here
    this.state = {
      searchterm: '',
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    // this.handleTabChange = this.handleTabChange.bind(this);
  }

  onInputChange(event) {
    this.setState({ searchterm: event.target.value });
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { // 13 is the enter keycode
      this.handleSearch();
    }
  }

  handleSearch() {
    if (this.state.searchterm !== '') {
      this.props.setSearchTerm(this.state.searchterm);
      this.search(this.state.searchterm);
    }
    this.props.history.push({
      pathname: '/search',
      state: {
        searchterm: this.state.searchterm,
      },
    });
  }

  search(text) {
    this.props.userSearch(text);
    this.props.activitySearch(text);
  }

  render() {
    return (
      <div>
        <TextField
          id="searchBarInput"
          type="search"
          placehoder="Search"
          margin="normal"
          onChange={this.onInputChange}
          onKeyPress={this.enterPressed}
        />
        <Button className="button" onClick={this.handleSearch}>
          <Icon className="fas fa-search" />
        </Button>
      </div>

    );
  }
}

const mapStateToProps = state => (
  {
    searchResults: state.searchResults,
  }
);
export default withRouter(connect(mapStateToProps, actions)(SearchBar));
