import { ActionTypes } from '../actions';

const searchResults = {
  current: [],
  user: [],
  activity: [],
  searchterm: '',
};

const SearchReducer = (state = searchResults, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH:
      return {
        current: action.payload,
        user: state.user,
        activity: state.activity,
        searchterm: state.searchterm,
      };
    case ActionTypes.SET_SEARCHTERM:
      return {
        current: state.current,
        user: state.user,
        activity: state.activity,
        searchterm: action.payload,
      };
    case ActionTypes.SEARCH_USER:
      return {
        current: state.current,
        user: action.payload,
        activity: state.activity,
        searchterm: state.searchterm,
      };
    case ActionTypes.SEARCH_ALL_USERS:
      console.log('IN SEARCH USERS');
      console.log(action.payload);
      return {
        current: state.current,
        user: action.payload,
        activity: state.activity,
        searchterm: state.searchterm,
      };
    case ActionTypes.SEARCH_ACTIVITY:
      return {
        current: state.current,
        user: state.user,
        activity: action.payload,
        searchterm: state.searchterm,
      };
    case ActionTypes.SEARCH_ALL_ACTIVITIES:
      console.log('IN SEARCH ACTIVITIES');
      console.log(action.payload);
      return {
        current: state.current,
        user: state.user,
        activity: action.payload,
        searchterm: state.searchterm,
      };
    default:
      return state;
  }
};

export default SearchReducer;
