import { ActionTypes } from '../actions';

const feed = {
  current: {},
};

const FeedReducer = (state = feed, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_FEED:
      return {
        current: action.payload,
      };
    default:
      return state;
  }
};

export default FeedReducer;
