import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import { ActionTypes } from '../actions';
import UserReducer from './user-reducer';
import SearchReducer from './search-reducer';
import FeedReducer from './feed-reducer';
import ActivityReducer from './activity-reducer';
import ConversationReducer from './conversation-reducer';


const appReducer = combineReducers({
  user: UserReducer,
  searchResults: SearchReducer,
  feed: FeedReducer,
  activity: ActivityReducer,
  conversations: ConversationReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOG_OUT:
      return {
        user: undefined,
      };
    case PURGE:
      return {}; // Return the initial state of this reducer to 'reset' the app
    default:
      return appReducer(state, action);
  }
};


export default rootReducer;
